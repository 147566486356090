<template lang="pug">
  .sale-stop-settings.container-fluid
    AppOverlayLoader.loader(:state="loading")
    .row.justify-content-between.m-t-5.m-b-15
      references(
        :references="references"
        :filters="filters"
        @change-reference="handleChangeReference"
      )
      CarClasses(
        :disabled="!isValidFilters"
        ref="carClassesTable"
        :carClasses="carClasses"
        :otaAccounts="otaAccounts"
        :sorting-data="sorting"
        @sorting="handleSorting"
        @handle-search="handleSearch"
        @change-item="setItem"
        @save-changes="saveChanges"
      )
</template>

<script>
  // store modules
  import saleStopSettingsModule from "@/config/store/sale_stop_settings"
  import inventoryGroupsModule from "@/config/store/matching/inventory_group"
  import shopsModule from "@/config/store/matching/shop"
  import otaAccountsModule from "@/config/store/ota_accounts"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import withConfirmation from "@/mixins/withConfirmation"
  import withToyotaOrganizationsMountedOrder from "@/mixins/withToyotaOrganizationsMountedOrder"

  // misc
  import { isEmpty } from "lodash-es"
  import { isSharedInventoryEnabled } from "@/helpers/organization"

  // components
  import References from "./References"
  import CarClasses from "./CarClasses"

  const saleStopSettingsMixin = withStoreModule(saleStopSettingsModule, {
    name: "saleStopSettings",
    readers: {
      carClasses: "items",
      loading: "loading",
      filters: "filters",
      pagination: "pagination",
      sorting: "sorting"
    },
    mutations: {
      setInventories: "SET_ITEMS",
      setFilters: "SET_FILTERS",
      setPagination: "SET_PAGINATION_DATA",
      setSaleStopSorting: "SET_SORTING",
      setItem: "SET_ITEM_BY_INDEX",
      setSettingSorting: "SET_SORTING"
    },
    actions: {
      fetchCarClasses: "FETCH_ITEMS",
      saveChanges: "UPDATE_ITEMS"
    },
    getters: ["itemsUpdated"]
  })

  const inventoryGroupsMixin = withStoreModule(inventoryGroupsModule, {
    name: "inventoryGroupsManagement",
    readers: { inventoryGroups: "items" },
    actions: { fetchInventoryGroups: "FETCH_ITEMS" },
    mutations: { setSorting: "SET_SORTING" }
  })

  const shopsMixin = withStoreModule(shopsModule, {
    name: "shopMatching",
    readers: { shops: "items" },
    actions: { fetchShops: "FETCH_ITEMS" }
  })

  const otaAccountsStoreMixin = withStoreModule(otaAccountsModule, {
    name: "otaAccounts",
    readers: {
      otaAccounts: "items"
    },
    actions: {
      fetchOtaAccounts: "FETCH_ITEMS"
    }
  })

  export default {
    components: {
      References,
      CarClasses,
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader")
    },

    mixins: [
      saleStopSettingsMixin,
      otaAccountsStoreMixin,
      inventoryGroupsMixin,
      withToyotaOrganizationsMountedOrder,
      withConfirmation,
      shopsMixin
    ],

    beforeRouteLeave(to, from, next) {
      this.beforeRouteLeaveHandler({ to, next, isChanges: this.hasChanges })
    },

    mounted() {
      this.setFilters({ search_value: null })
      this.setSettingSorting({ field: this.sortingField, direction: "asc" })
      this.fetchOtaAccounts()
      if (isSharedInventoryEnabled()) {
        this.fetchInventoryGroups({ pagination: { _disabled: true }, matched_only: true, with_shops_only: true })
      } else {
        this.fetchShops({ pagination: { _disabled: true }, matched_only: true, synced_only: true })
      }

      if (this.isValidFilters) this.fetchCarClasses()
    },

    computed: {
      hasChanges({ itemsUpdated }) {
        return !isEmpty(itemsUpdated)
      },

      isValidFilters() {
        return !isEmpty(this.filters.reference)
      },

      references({ inventoryGroups, shops }) {
        return isSharedInventoryEnabled() ? inventoryGroups : shops
      },

      sortingField() {
        return isSharedInventoryEnabled() ? "inventory_groups_car_classes.order" : "shops_car_classes.order"
      }
    },

    methods: {
      isEmpty,

      handleChangeReference(reference) {
        this.withConfirmAction(() => {
          this.setFilters({ reference })
          this.$refs.carClassesTable.cancelValidation()
          this.fetchCarClasses()
        })
      },

      handleSearch(searchValue) {
        this.setFilters({ search_value: searchValue })
        this.fetchCarClasses()
      },

      handleSorting(sorting) {
        if (this.isValidFilters) {
          this.setSaleStopSorting(sorting)
          this.fetchCarClasses()
        }
      },

      withConfirmAction(callback) {
        this.$conditionalConfirm({
          useConfirm: this.hasChanges,
          title: this.$t("inventory_management.continue_without_save"),
          handler: callback
        })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/shared-inventory-management.sass"

  .sale-stop-settings
    position: relative

    .loader
      z-index: 10000002
</style>
